import React, { useContext, useEffect } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { UserSettingsProvider } from 'utils/hooks/useAccountSettings/context';
import { AnnouncementsProvider } from 'utils/hooks/useAnnouncements/context';
import { UserContext } from 'utils/hooks/useUserContext/context';
import {
  PageSectionType,
  Product,
  MembershipProductType,
  MembershipTypeOptions,
  Announcement,
  HeroSectionType,
} from 'utils/types';
import NOTIFICATIONS from '../../../components/ToastNotification/constants';
import { NotificationContext } from '../../Notification';
import MemberViewContainer from '../MemberViewContainer';
import NonMemberViewContainer from '../NonMemberViewContainer';

type Props = {
  membershipProduct: MembershipProductType;
  data: {
    product: Product;
    content: {
      announcement: Announcement;
      heroSection: HeroSectionType;
      sections: PageSectionType[];
      authorizedSections: PageSectionType[];
    };
  };
  location: any;
};

export const ControlMembershipContainer: React.FunctionComponent<Props> = ({
  membershipProduct,
  data,
  ...props
}) => {
  const { location } = props;
  const {
    userData: { loggedIn, isMember, membershipType, fetched, membershipState },
  } = useContext(UserContext);
  const { setNotificationData } = useContext(NotificationContext);
  const { content } = data;
  const { sections, authorizedSections } = content;

  useEffect(() => {
    if (fetched) {
      const pageTitle = isMember
        ? 'Control Pilot - Test Credit Page'
        : 'Control Pilot - Enrollment Page';
      ewAnalytics.track({
        data: {
          Page: pageTitle,
        },
        event: ANALYTICS.EVENTS.CONTROL_VIEWED_PAGE,
      });
      if (isMember && membershipState === 'failed') {
        setNotificationData(NOTIFICATIONS.PAYMENT_FAILED(pageTitle));
      }
    }
  }, [fetched]);

  useEffect(() => {
    if (location?.state?.notifyCurrentMember) {
      setNotificationData(
        NOTIFICATIONS.ALREADY_A_MEMBER(
          'Membership Hero',
          membershipType ?? MembershipTypeOptions.control,
        ),
      );
      const { state } = location;
      delete state.notifyCurrentMember;
      window.history.replaceState(state, '');
    }
  }, [location?.state]);

  const memberAuthorized = loggedIn && isMember && membershipType === 'control';

  const memberContext = memberAuthorized ? (
    <MemberViewContainer
      membershipProduct={membershipProduct}
      membershipType={membershipType ?? ''}
      pageSections={authorizedSections}
      isMember
      data={data}
      {...props}
    />
  ) : (
    <NonMemberViewContainer
      membershipProduct={membershipProduct}
      pageSections={sections}
      loggedIn={loggedIn}
      isMember={false}
      data={data}
      {...props}
    />
  );

  return (
    <UserSettingsProvider>
      <AnnouncementsProvider>{memberContext}</AnnouncementsProvider>
    </UserSettingsProvider>
  );
};

export default ControlMembershipContainer;
